<template>
  <v-container fill-height fluid id="login" style="overflow=hidden">
    <v-layout align-center justify-center column fill-height>
      <v-card-text class="text-xs-center">
        <v-layout row wrap fill-height align-center justify-center>
          <v-flex xs12>
            <v-card-text class="text-xs-center">
              <h1 class="display-3">404</h1>
              <h2 class="display-1">Hey there! How did you get here?</h2>
              <span class="caption mt-2"
                >The page you requested "{{ $route.path }}"" was not
                found.</span
              >
            </v-card-text>
            <div class="text-center">
              <v-btn color="warning" to="/">Home</v-btn>
            </div>
          </v-flex>
        </v-layout>
      </v-card-text>
    </v-layout>
  </v-container>
</template>
<script>
export default {};
</script>
